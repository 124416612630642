// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

@font-face {
    font-family: Inter;
    src: url(../assets/fonts/Inter-Regular.woff);
}

:root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;
    --ion-color-primary-grey: #B0B0B0;
    --ion-color-primary-white: #D9D9D9;
    /** secondary **/
    --ion-color-secondary: #0cd1e8;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0bb8cc;
    --ion-color-secondary-tint: #24d6ea;
    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;
    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;
    --ion-color-success-dark: #52C41A;
    --ion-color-success-light: #B7EB8F;
    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;
    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
    --ion-color-dark-grey: #595959;
    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
    --ion-color-medium-black: #5E5E5E;
    --ion-color-medium-white: #F6FFED;
    --ion-color-medium-grey: #C6C6C6;
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --ion-color-light-blue: #5289FF;
    --ion-color-light-grey: #AAAAAA;
    --ion-color-light-white: #FFFCF9;
    --ion-background-color: #F5F5F5;
    --ion-toolbar-background: #383838;
    --ion-toolbar-color: #fff;
    --shadow: 0 0.3px 0.9px #0000001c, 0 1.6px 3.6px #00000021 !important;
    //sidemenu
    --active-sidemenu-color: #f27924;
    --active-sidemenu-background-color: #fff4eb;
    --active-background-color: #fff4eb;
    --active-color: #f27924;
    --sidemenu-color: #8c8c8c;
    //font
    --font-family-datasavi: Inter,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    //primary button
    --primary-background-color: #f27924 !important;
    --primary-font-color: #ffffff !important;
    //secondary button
    --secondary-background-color: #FFF9F0 !important;
    --secondary-font-color: #f27924 !important;
    //button hover color
    --hover-font-color: #f27924 !important;
    --hover-background-color: #FFFCF6 !important;
    //footer sidebar
    --footer-dark-background-color: #faefe0;
    --footer-background-color: #FEFCF9;
    //cards
    --card-header-background-color: #fafafa;
    --card-title-background-color: #FFFCF6;
    --panel-border-color: #EEEEEE;
    --panel-background-color: #F9F9F9;
}



ion-menu {
    --ion-background-color: #5e6966;
}

a:active {
    --anchor-text-color: #f27924 !important;
}

a {
    --anchor-text-color: #8c8c8c;
    color: #8c8c8c !important;
    ;
}

a:hover {
    --anchor-text-hover-color: #f27924 !important;
    color: #f27924 !important;
}


$split-pane-side-max-width: 12%;
