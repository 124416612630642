// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import '~primeicons/primeicons.css';

// @import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
    font-family: Inter;
    src: url(./assets/fonts/Inter-Regular.woff);
}

.p-inputtext {
    font-family: var(--font-family-datasavi) !important;
    font-size: 0.8rem !important;
}

///////////////////////////    Material Icon start /////////////////
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

///////////////////////////    Material Icon End /////////////////////

//////////////////Footer sidebar/////////////////////////
.footer-sidebar {
    max-height: 70px !important;
    background-color: var(--footer-dark-background-color) !important;
    font-family: var(--font-family-datasavi);
    text-align: right;

    .p-sidebar .p-sidebar-content {
        padding: 15px 32px 15px 32px !important;
    }

    .p-sidebar-content .p-button {
        // margin-left: 8px;
        margin-top: 1rem;
        justify-content: center;
        align-items: center;
        padding: 8px 24px;
        width: 120px;
        height: 40px;
        border: 1px solid var(--anchor-text-color);
        border-radius: 2px;
    }

    .p-sidebar-content .p-button-secondary {
        background: var(--secondary-background-color);
        color: var(--secondary-font-color);
        border: solid 1px var(--secondary-font-color);
        margin-right: 1rem;
    }

    .p-sidebar-content .p-button-secondary:hover {
        background: var(--hover-background-color);
        color: var(--hover-font-color);
    }

    .p-sidebar-content .p-button-primary {
        background: var(--primary-background-color);
        color: var(--primary-font-color);
        border: solid 1px var(--primary-font-color);
        margin-right: 2rem
    }

    .p-sidebar-content .p-button-primary:hover {
        background: var(--hover-background-color);
        color: var(--hover-font-color);
        border: solid 1px var(--hover-font-color);
    }
}

.datasetDropdown {
    height: 70px !important;
    position: sticky;

    .p-sidebar .p-sidebar-content {
        height: 70px !important;
        background-color: #f1efef;
        z-index: 1003;
        box-shadow: none !important;

        .dropdown {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            margin-right: 15px;
        }
    }

    .p-sidebar-bottom {
        height: 9rem !important;
    }
}

///////////////////////////////////////////////////////////

///////////////////////////   .p-sidebar start /////////////////////

.p-sidebar {
    border: 0 !important;
    padding: 0 !important;

    // background: #f5f5f5 !important;
    .p-sidebar-close {
        z-index: 100 !important;
    }

    .p-sidebar-content {
        height: 100% !important;
        background-color: var(--ion-color-light-white);
    }
}

.p-sidebar-top {
    height: 7rem !important;
}

.p-sidebar-right {
    padding: 0 !important;

    .p-sidebar-header,
    .p-sidebar-content {
        padding: 0 !important;
    }

    .p-sidebar-close {
        position: absolute;
        top: 5px;
        right: 20px;
    }
}

///////////////////////////    .p-sidebar end /////////////////////


///////////////////////////    formwithcalendar start /////////////////////

.formwithcalendar {
    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
        color: black;
    }

    .p-datepicker .p-timepicker {
        border-top: 0 !important;

        button {
            color: white !important;
        }
    }

    .p-datepicker-buttonbar {
        border-top: 0 !important;

        .p-button {
            background-color: #1bb1e7 !important;
        }
    }

    .p-datepicker {
        //background: #5e6966 !important;
        background: #7a7a7a !important;
        //box-shadow: 0px 4px 10px #001d366e !important;
        color: #fff !important;

        .p-datepicker-header {
            background: transparent !important;

            .p-datepicker-month,
            .p-datepicker-year,
            .p-datepicker-prev,
            .p-datepicker-next {
                background: transparent !important;
                border: 0 !important;
                color: white !important;
                font-weight: normal;

                option {
                    color: #333 !important;
                }
            }
        }
    }

    .p-datepicker table th>span,
    .p-datepicker table td>span {
        font-weight: normal !important;
    }

    .formwithcalendar-left {
        border-right: 2px solid #9e818152 !important;
    }

    .formwithcalendar-center {
        padding: 0px 15px !important;
    }
}

///////////////////////////    formwithcalendar end /////////////////////


a:hover {
    cursor: pointer;
}

///////////////////////////    p-tree started    /////////////////////

.tree_panel {
    .p-panel-header {
        padding: 0.5rem 1.38rem 0.2rem 1.38rem !important;
    }
}

.p-tree {
    width: 100% !important;
    padding: 0 !important;
    border: 0 !important;

    .p-tree-container .p-treenode .p-treenode-content {
        padding: 1px !important;

        .p-treenode-icon {
            display: none !important;
        }

        .p-treenode-label.p-state-highlight {
            color: blue !important;
            background-color: #ffffff !important;
        }

        .p-tree-toggler {
            width: 1.2rem !important;
            height: 1.2rem !important;
        }
    }

    .p-treenode-children .p-treenode-label {
        background: transparent !important;
    }

    .pi {
        font-size: 0.9em !important;
    }

    .p-tree-filter-container {
        .p-inputtext {
            padding: 2px !important;
            border: 1px solid #c8c8c838 !important;
        }

        .p-tree-filter-icon {
            margin-top: -0.3em !important;
            right: 1em !important;
        }
    }
}

///////////////////////////    p-tree end    /////////////////////


// /******scrollbar of .UI-table start ******/

.p-datatable,
.p-treetable,
.p-panel,
.p-tree {
    *::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
        border-radius: 0px !important;
        background-color: #fff !important;
    }

    *::-webkit-scrollbar {
        width: 3px !important;
        height: 0px !important;
        background-color: #fff !important;
    }

    *::-webkit-scrollbar-thumb {
        border-radius: 0px !important;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)!important;
    }

    &:hover {
        *::-webkit-scrollbar-thumb {
            background-color: lightgray !important;
        }

        *::-webkit-scrollbar {
            width: 3px !important;
            height: 5px !important;
            // background-color: #F5F5F5!important;
        }
    }
}

// /******scrollbar of .UI-table end ******/


// /****************************TABVIEW************************************/
.p-tabview {
    margin-bottom: 15px !important;

    .p-tabview-title {
        font-family: var(--font-family-datasavi);
        font-size: 0.85rem !important;
    }

    .p-tabview-nav {

        //background: white !important;
        li.p-highlight .p-tabview-nav-link {
            //border: 0 !important;
            background: var(--active-background-color) !important;
            border: 1px solid var(--active-color) !important;
            border-top-left-radius: 10px !important;
            border-top-right-radius: 10px !important;
            color: var(--active-color) !important;
            opacity: 1 !important;
            // font-weight: 700 !important;
            // font-size:0.9rem!important;
        }

        .p-tabview-nav-link {
            //border: 0 !important;
            transition: none !important;
            background: #fff !important;
            padding: 0.5rem 1rem !important;
            font-weight: 500 !important;
            margin: 0 !important;
            border-top-left-radius: 10px !important;
            border-top-right-radius: 10px !important;
        }

        li .p-tabview-nav-link:not(.p-disabled):focus {
            box-shadow: none !important;
        }
    }

    .p-tabview-panels {
        // padding: 0!important;
        // padding: 0.571rem 1rem !important;
        padding: 0.5rem !important;
        border-radius: 4px;

        .panel_includes_table {
            .p-card {
                padding: 0.2rem !important;

                .p-card-content {
                    padding-left: 0.5rem !important;
                    padding-top: 0.5rem !important;
                    padding-right: 0.5rem !important;
                }

                .tile-value {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    color: #FF914C;
                    /*font-size: 1.5rem !important;*/
                }

                .tile-key {
                    /*line-height: 200%;
                    font-size: 0.8rem !important;*/
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    color: #7E7E7E;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;

                    p {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 12px;
                        color: #7E7E7E;
                        margin: 0 !important;
                    }
                }
            }

            background-color: #F9F9F9 !important;

            // border: solid 1px var(--panel-border-color);
            .p-panel-header {
                font-family: var(--font-family-datasavi);
                font-size: 0.7rem !important;
                margin-bottom: -8px;
                // padding: 0rem 1rem !important;
            }

            .p-panel-icons {
                font-size: 0.7rem !important;
                color: #5289FF;
            }

            // .p-panel-content {
            //     padding: 0rem 1rem !important;
            // }
            .p-fileupload {
                display: flex !important;

                .p-fileupload-row>div {
                    width: auto !important;
                }
            }
        }

        .p-panel-header {
            padding: 0rem 1rem !important;

            .p-panel-header-icon {
                margin: 2px !important;
            }
        }

        .p-panel-content {
            padding: 0 !important
        }

        .p-panel {
            box-shadow: none !important;
        }

        .p-grid {
            margin-top: 0 !important;
        }

        .p-datatable {
            margin-top: 10px;
        }
    }
}

// /****************************END of TABVIEW************************************/




// /**************************** Start of TABLE************************************/

.p-datatable .p-paginator {
    font-size: 10px !important;
    margin-top: 3px;
}

.p-paginator .p-dropdown .p-dropdown-label {
    margin-top: -5px !important;
}

.p-paginator .p-dropdown {
    height: 1rem !important;

    .p-dropdown-trigger {
        height: 1rem !important;
    }
}

.p-paginator .p-paginator-pages .p-paginator-page {
    height: 1.8rem !important;
}

.p-paginator .p-paginator-current {
    height: 1rem !important;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    height: 1rem !important;
}

.p-datatable th {
    border: 1px solid #c8c8c870 !important;
    font-size: 11px;
    padding: 0px 2px 0px 5px !important;
    white-space: normal !important;
    // overflow: auto !important;
    // text-overflow: ellipsis !important;
    // max-height: 22px;
    min-height: 22px !important;

    // height: 22px !important;
    .p-inputtext {
        padding: 0 !important;
        line-height: 200% !important;
    }

    p-calendar .p-inputtext,
    .p-calendar,
    .p-dropdown,
    .p-multiselect,
    .p-inputtext,
    .p-inputnumber {
        width: 64%;
        border-color: #c8c8c838 !important;
    }

    .p-dropdown {
        min-width: 7.5em !important;
        min-height: 14px !important;
        max-height: 20px !important;

        .p-inputtext {
            padding: 0 10px 2px 0 !important;
            text-align: left !important;
            line-height: 14px;
        }
    }

    .p-multiselect-panel,
    .p-dropdown-panel {
        min-width: 300px !important;
        width: auto !important;
    }
}

td {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.p-datatable td {
    //color: #333333 !important;
    min-height: 20px !important;
    height: 20px !important;
    padding: 0px 2px 0px 5px !important;
    border-bottom: 1px solid #f4f4f4 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;

    //border:  1px solid #f4f4f4 !important;
    a {
        cursor: pointer !important;
    }

    .p-inputtext {
        padding: 0 !important;
        font-size: 0.7rem !important;
        text-align: right !important;
    }

    .p-chkbox {
        width: 12px !important;
        height: 13px !important;

        .p-chkbox-box {
            width: 15px !important;
            height: 15px !important;
        }
    }

    .p-dropdown .p-dropdown-clear-icon {
        // display: none;
        color: #84848480 !important;

        &:hover {
            // display: inline !important;
            color: #848484 !important;
        }
    }

    .p-dropdown .p-dropdown-trigger {
        color: #84848480 !important;

        &:hover {
            color: #848484 !important;
        }
    }

    .p-inputtext,
    .p-dropdown,
    .p-multiselect,
    .p-multiselect-trigger,
    .p-dropdown-trigger {
        //input box color
        background-color: transparent !important;
        border: 0 !important;
    }

    .p-state-disabled,
    .p-widget:disabled,
    .p-component:disabled {
        opacity: 1 !important;
    }

    p-calendar .p-inputtext,
    .p-calendar,
    .p-dropdown,
    .p-multiselect,
    .p-inputtext,
    .p-inputnumber {
        width: 100%;
    }

    // <!-- Sudarshan - Radio Button -->
    .p-dropdown {
        min-width: 7.5em !important;
        min-height: 14px !important;
        max-height: 20px !important;

        .p-inputtext {
            padding: 0 10px 2px 0 !important;
            text-align: left !important;
            line-height: 10px;
        }
    }

    .p-multiselect-panel,
    .p-dropdown-panel {
        min-width: 300px !important;
        width: auto !important;
    }

    .p-inputnumber-input {
        // <!-- Sudarshan - Radio Button -->
        text-align: left !important;
        //XXXXXXXXXXXXXXXXX
    }

    .DropDownList {
        text-align: left !important;
    }
}

.align-right,
.int,
.decimal,
.real,
.date,
.datetime,
.month {
    text-align: left !important;


    * {
        text-align: left !important;
        // white-space: nowrap !important;
        // overflow: hidden !important;
        // text-overflow: ellipsis !important;
    }

    // justify-content: end !important;
    th {
        text-align: left !important;
        padding-right: 2px !important;
    }
}

// Sudarshan - Context Menu
td{
    .p-button.p-button-icon-only.p-button-rounded{
        width:10px!important;
        height:10px!important;
        border-radius: 50%!important;
    }
}

td{

    .p-button.p-button-text{
        font-size: 11px!important;
        height:17px!important;
        padding-left: 0;
    }

}
//XXXXXXXXXXXXXXXXXXXXXXXXXX

th.int,
th.date,
th.datetime,
th.decimal,
th.month,
td.int,
td.date,
td.datetime,
td.decimal,
td.month {
    text-align: left !important;
    padding-right: 2px !important;
}

td.boolean,
td.Checkbox,
th.boolean,
th.Checkbox {
    text-align: center !important;
}

// Sudarshan's Code

td.boolean,
td.Switch,
th.boolean,
th.Switch {
    text-align: center !important;
}

//XXXXXXXXXXXXXXXXXX

.p-datatable td .p-calendar .p-inputtext {
    text-align: left !important;
}

.align-left,
.DropDownList,
.string,
.Text {
    text-align: left !important;
    align-items: right;

    * {
        text-align: left !important;
        // white-space: nowrap !important;
        // overflow: hidden !important;
        // text-overflow: ellipsis !important;
    }

    padding-left: 0px !important;
}

//xxxxxxxxxxxxxxxxxxxxxxx Sudarshan - Radio Button xxxxxxxxxxxxxxxxxxx 

.inputbox {
    border-bottom: 1px solid #c8c8c838;
    min-height: 15px !important;

    &:hover {
        border-bottom: 1px solid gray;
        color: gray;
        background-color: white;
    }
}

.p-sortable-column .p-sortable-column-icon {
    display: none;

    &::hover {
        color: #848484;
        margin-left: 0.5rem;
        display: inline;
    }
}

.p-datatable-scrollable-header-box,
.p-datatable-scrollable-footer-box,
.p-treetable-unfrozen-view .p-treetable-scrollable-header-box {
    background-color: #fff !important;
    padding-right: 3px !important;
}

.p-datatable-tfoot td {
    background-color: #fff !important;
    line-height: 2rem;
}

.p-paginator .p-dropdown {
    max-width: 80px !important;
}

.p-paginator {

    * {
        font-size: 11px !important;
    }

    // padding-top:20px !important;
    .p-dropdown {
        float: left !important;
    }

    .p-paginator-current {
        float: right !important;
    }
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    min-width: 1rem !important;
    width: 1rem !important
}

.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 1rem !important;
    width: 1rem !important;
}

.p-paginator {
    background-color: transparent !important;
    border: 0 !important;

    .p-paginator-pages .p-paginator-page.p-highlight {
        color: #007ad9 !important;
        background-color: transparent !important;
        border-color: transparent !important;
    }

    .p-dropdown {
        border: 0 !important;
    }
}

.p-dropdown-clear-icon {
    margin-top: -0.4rem !important;
    right: 2rem !important;
}

.p-datatable-footer {
    border: 0 !important;
    padding: 0.5rem 0 !important;
}

.footer-sigma-icon {
    float: left !important;
    text-align: left;
}

.pi-percentage {
    margin-top: -5px !important;
}

.pi-percentage:before {
    content: "\03a3" !important;
    font-size: 1rem !important;
}

.p-datatable-frozen-view {
    .p-datatable-tfoot>tr>td {
        //display: none !important;
        border: 0 !important;
    }
}

td.hide-blank-footer-cell {
    border: 0 !important;
}

.p-datatable-scrollable-body {
    max-height: 100% !important;
    overflow: auto !important;
    position: relative !important;
}

// /**************************** TABLE CSS  End ************************************/


// /**************************** DatePicker Start ************************************/

.p-datepicker {
    table td {
        padding: 0 !important;
    }

    table td>span {
        width: 2rem !important;
        height: 2rem !important;
    }
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.4rem !important;
}

.p-datepicker .p-datepicker-header {
    padding: 0 !important;
}

// /**************************** DatePicker End ************************************/

// /*------- Button css Start  ----*/

.p-button:focus {
    box-shadow: none !important;
}


// /*------- Button css End  ----*/



// /*------- Filter Start  ----*/


.p-splitbutton {
    .p-button {
        color: #007ad9 !important;
        background-color: #fff !important;
        border: 0 !important;
        //box-shadow: 0 4px 10px #001d360a;
    }
}

.filter {
    .p-button {
        color: #007ad9 !important;
        background-color: #fff !important;
    }

    .p-dropdown,
    .p-multiselect,
    .p-button,
    .p-calendar .p-inputtext {
        //border: 0 !important;
        //box-shadow: 0 4px 10px #001d360a !important;
        line-height: 1.4 !important;
        background-color: #fff !important;
    }

    .p-multiselect .p-multiselect-label {
        padding: 0.429rem 0.729rem !important;
    }

    .p-dropdown.p-dropdown-clearable .p-dropdown-label {
        padding-right: 1.429rem !important;
        padding-left: 0.729rem !important;
    }

    * {
        font-size: 0.8rem !important;
    }
}

.open-filter {
    .p-grid {
        justify-content: flex-end !important;
        margin-bottom: 10px !important;
    }

    .p-sm-1,
    .p-sm-2,
    .p-sm-3,
    .p-sm-4,
    .p-sm-5,
    .p-sm-6,
    .p-sm-7,
    .p-sm-8,
    .p-sm-9,
    .p-sm-10,
    .p-sm-11,
    .p-sm-12,
    .p-md-1,
    .p-md-2,
    .p-md-3,
    .p-md-4,
    .p-md-5,
    .p-md-6,
    .p-md-7,
    .p-md-8,
    .p-md-9,
    .p-md-10,
    .p-md-11,
    .p-md-12,
    .p-lg-1,
    .p-lg-2,
    .p-lg-3,
    .p-lg-4,
    .p-lg-5,
    .p-lg-6,
    .p-lg-7,
    .p-lg-8,
    .p-lg-9,
    .p-lg-10,
    .p-lg-11,
    .p-lg-12,
    .p-xl-1,
    .p-xl-2,
    .p-xl-3,
    .p-xl-4,
    .p-xl-5,
    .p-xl-6,
    .p-xl-7,
    .p-xl-8,
    .p-xl-9,
    .p-xl-10,
    .p-xl-11,
    .p-xl-12 {
        padding: 0 2px !important;
    }

    .filterInputs {
        margin-top: 10px;
    }

    .p-field {
        margin-bottom: 2px !important;
    }
}

.panel-filter {
    width: 20%;
    z-index: 200;
    position: fixed !important;
    right: 0;
    top: 0;
    margin: 0 !important;
    height: 100%;
    background-color: white;
    overflow: auto;
    padding: 15px;

    .p-field,
    .p-col-12 {
        margin-bottom: 5px !important;
        width: 100% !important;
    }

    .p-button {
        display: initial !important;
    }
}

// /*------- Filter end  ----*/

// /*------- p-progressbar Start  ----*/

.p-progressbar {
    background-color: transparent !important;
    border-radius: 0% !important;
}

.positive-pbar .p-progressbar .p-progressbar-value {
    background: lightgreen !important;
}

.negative-pbar .p-progressbar .p-progressbar-value {
    background: red !important;
}

.negative-pbar-greater-than-100 .p-progressbar .p-progressbar-value {
    background: red !important;
}

.positive-pbar-greater-than-100 .p-progressbar .p-progressbar-value {
    background: yellowgreen !important;
}

// /*------- p-progressbar end  ----*/






// ////////////////Prime NG Treetable//////////////////////////////////
.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
    width: 1rem !important;
    height: 0.9rem !important;
}

.p-treetable .p-treetable-tbody>tr {
    background-color: none !important;
}

.p-treetable .p-treetable-tbody>tr:nth-child(even) {
    background-color: none !important;
}

.p-treetable .p-treetable-tbody>tr:nth-child(odd) {
    background-color: none !important;
}

.p-treetable .p-treetable-thead>tr>th {
    border: 1px solid #c8c8c870 !important;
    padding: 2px !important;
    font-size: 11px !important;
    font-weight: normal !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-height: 22px;
    min-height: 19px !important;
    height: 22px !important;

    input {
        // border:0 !important;
        // background-color: transparent !important;
        width: 95%;
        min-height: 20px !important;
        text-align: center;
    }
}

.p-treetable .p-treetable-tbody>tr>td {
    border: 1px solid #c8c8c870 !important;
    font-size: 11px;
    padding: 2px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-height: 23px;
    min-height: 19px !important;
    height: 23px !important;
}

.p-treetable .p-treetable-tfoot>tr>td {
    //border:1px !important solid #c3c3c3;
    font-size: 11px;
    padding: 2px !important;
    max-height: 20px;
    min-height: 19px !important;
    height: 20px !important;
}

.p-treetable .p-paginator-bottom {
    border: 0px solid #c8c8c870 !important;
}

.p-tree .p-treenode-parent .p-treenode-label {
    background-color: #CCCCCC;
}

.p-tree .p-treenode-children .p-treenode-label {
    background-color: #E1DFD7;
}

.p-treetable .p-editable-column input {
    color: #333333;
    width: 100%;
    text-align: center;
    height: 13px;
    font-size: 12px;
}

body .p-treetable .p-treetable-summary {
    border: 1px solid #c8c8c870 !important;
}


.treeinputbox {
    border-bottom: 1px solid #c8c8c838;
    min-height: 15px !important;

    &:hover {
        border-bottom: 1px solid gray;
        color: gray;
        background-color: white;
    }
}

// /////////////////////End PrimeNG Treetable//////////////////////








// ////////////////////////Dataview///////////////////////////////
.p-dataview {
    padding: 0px !important;

    .p-paginator-bottom {
        background: white !important;
        margin: 0.5rem 2px 0px 2px !important;
    }
}

.p-dataview-header {
    background: none !important;
    border: 0 !important;
    padding: 0 0 0rem 0 !important;

    .p-selectbutton .p-button.p-highlight {
        background: #dadada !important;
        color: black !important;
        border-color: #dadada;
    }

    .p-selectbutton .p-button {
        background: white !important;
        color: black !important;
        border-color: #dadada;
    }

    .p-d-flex {
        flex-direction: row-reverse !important;
    }

    .p-dataview-layout-options {
        margin: 1px !important;
    }
}

.p-dataview-content {
    padding-top: 10px !important;
    background: none !important;
    height: auto !important;
    overflow: auto !important;
    margin: -0.2rem !important;
}

.p-dataview.p-dataview-list {

    .product-grid-item-top {
        display: flex;
        justify-content: space-between;
        // padding-bottom: 5px;
        // margin-bottom: 5px;
        //border-bottom: 1px solid #add8e66b;
    }

    .product-grid-item-bottom {
        // display: flex;
        // justify-content: space-between;
        display: none;
        padding-top: 5px;
        margin-top: 5px;
        border-top: 1px solid #add8e66b;
        line-height: 2;

        .p-button {
            color: #007ad9 !important;
            border-radius: 15px !important;
            padding: 5px 15px !important;

            .p-button-label {
                font-size: 12px !important;
            }

            margin-right: -5px !important;
            background-color: transparent !important;
            border: 1px solid #c8c8c8a1 !important;
        }

        .product-key-parameter {
            display: flex;
            justify-content: space-between;
        }
    }

    .product-grid-item-content {

        //padding: 10px;
        .product-data {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            div {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: baseline;
            }
        }
    }

    .product-grid-item.card:hover {
        background: var(--ion-background-color) !important;
        border-radius: 0.4rem !important;
        cursor: pointer;
        /*padding: 10px !important;*/
    }

    .highlightcard .product-grid-item.card {
        background: var(--ion-background-color) !important;
        border-radius: 0.4rem !important;
        cursor: pointer;
        /*padding: 10px !important;*/
    }
}

.p-dataview.p-dataview-list .p-dataview-content>.p-grid>div {
    border: 0 !important;
}

.product-list-detail,
.product-list-action {
    justify-content: space-between;
    display: flex;
}

.product-grid-item.card,
.product-list-item.card {
    box-shadow: var(--shadow);
    padding: 5px 15px 5px 15px;
    background: white !important;
    margin: 0 5px;
    border-radius: 3px !important;
}

.list-view {
    .product-grid-item {
        .product-grid-item-content {
            .product-data {
                div>span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }
}


.grid-view {

    .product-grid-item.card,
    .product-list-item.card {
        box-shadow: var(--shadow);
        // * {
        //     font-size: 1rem !important;
        // }
        box-shadow: 0 4px 10px #001d360a;
        //border: 1px solid #c8c8c8;
        padding: 0px !important;
        background: white !important;
        margin: 5px;
        border-radius: 3px !important;
    }

    .product-grid-item-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0px;
        margin-bottom: 0px;
        width: 100%;
        height: 40px;
        background-color: #FFFCF6;
    }

    .product-grid-item-top b {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        color: #262626;
        margin-top: 4px;
        margin-left: 10px;
    }

    .product-grid-item-bottom {
        // display: flex;
        // justify-content: space-between;
        padding: 5px 10px 0px 10px;
        margin-top: 0px;
        /*border-top: 1px solid #add8e66b;*/
        line-height: 2;
        width: 100%;
        border-top: 0.6px solid #D9D9D9;
        height: 40px;

        .p-button {
            color: #007ad9 !important;
            border-radius: 15px !important;
            padding: 0px 5px !important;

            .p-button-label {
                font-size: 12px !important;
            }

            margin-right: -5px !important;
            background-color: transparent !important;
            border: 1px solid #c8c8c8a1 !important;
        }

        .product-key-parameter {
            display: flex;
            justify-content: space-between;

            div>span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
            }
        }
    }

    .product-grid-item-content {
        padding: 15px 10px 15px 10px;

        .product-data {

            // b {
            //     font-size: px !important;
            // }
            div {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #5E5E5E;
            }
        }
    }
}

// /////////////////End of Dataview//////////////////////




input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 2px;
}


.mobile-icon {
    display: none !important;
}

// /////////////////  Mobile View   //////////////////////

@media(max-width:900px) {

    .search-box {
        margin-top: 0px;
        height: 30px;
        max-width: 140px;
    }

    .p-datatable-scrollable-wrapper {
        overflow: auto !important;

        .p-datatable-unfrozen-view {
            width: auto !important;
        }
    }

    .p-menubar {
        overflow-x: auto !important;

        .p-menuitem-link {
            width: max-content !important;
            padding: 0.557rem !important;
            font-family: var(--font-family-datasavi);
        }

        .p-menubar-button {
            display: none !important;
        }

        .p-menubar-root-list {
            display: flex !important;
            flex-direction: row !important;
            position: relative !important;
            border: 0 !important;
            box-shadow: none !important;
        }
    }


    .panel-filter {
        width: 100% !important;
        min-width: 100% !important;
        padding: 5px !important;
    }

    .open-filter {
        width: 97% !important;
        min-width: 97% !important;
    }

    .main-footer {
        display: flex;
        flex-direction: column !important;
        text-align: center;

        #copyright_text {
            display: none;
            align-items: center;
        }
    }


    .ui-paginator {
        padding-top: 20px !important;

        .ui-dropdown {
            float: none !important;
            display: block !important;
            margin: auto !important;
        }

        .ui-paginator-current {
            float: none !important;
            display: block !important;
            margin: auto !important;
        }
    }
}

// /////////////////  Mobile View   //////////////////////

// /////////////////  Inquizity Loader start  //////////////////////

#cover {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background: rgba(0, 0, 0, 0.35) !important;
    z-index: 10000 !important;
    width: 100% !important;
    height: 500% !important;
    cursor: wait !important;

    .message {
        background: -webkit-linear-gradient(top, #ffffff, #fdf8ff);
        position: absolute !important;
        transform: translate(89%, 49%) !important;
        width: 35% !important;
        text-align: center !important;
        padding: 3% !important;
        box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
    }
}

// /////////////////  Inquizity Loader End  //////////////////////


// /////////////////  FileUpload start  //////////////////////


.requiredmark {
    color: red;
    padding-right: 4px;
}

.p-fileupload {
    .p-fileupload-content {
        background-color: transparent !important;
        padding: 0 !important;
        border: 0 !important;
        color: #333333;
    }

    .p-fileupload-buttonbar {
        background-color: transparent !important;
        padding: 0 !important;
        border: 0 !important;
        color: #333333;
        border-bottom: 0 none;
    }

    .p-button {
        background-color: #ffffff !important;
        color: #333333 !important;
        max-width: 100%;
    }

    .p-button-label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

// /////////////////  FileUpload end  //////////////////////

//////////////// primeng grid css start  ////////////

.p-grid {
    margin: -0.2rem -0.2rem 0rem -0.2rem !important;

    .p-sm-1,
    .p-sm-2,
    .p-sm-3,
    .p-sm-4,
    .p-sm-5,
    .p-sm-6,
    .p-sm-7,
    .p-sm-8,
    .p-sm-9,
    .p-sm-10,
    .p-sm-11,
    .p-sm-12,
    .p-md-1,
    .p-md-2,
    .p-md-3,
    .p-md-4,
    .p-md-5,
    .p-md-6,
    .p-md-7,
    .p-md-8,
    .p-md-9,
    .p-md-10,
    .p-md-11,
    .p-md-12,
    .p-lg-1,
    .p-lg-2,
    .p-lg-3,
    .p-lg-4,
    .p-lg-5,
    .p-lg-6,
    .p-lg-7,
    .p-lg-8,
    .p-lg-9,
    .p-lg-10,
    .p-lg-11,
    .p-lg-12,
    .p-xl-1,
    .p-xl-2,
    .p-xl-3,
    .p-xl-4,
    .p-xl-5,
    .p-xl-6,
    .p-xl-7,
    .p-xl-8,
    .p-xl-9,
    .p-xl-10,
    .p-xl-11,
    .p-xl-12,
    .p-col-1,
    .p-col-2,
    .p-col-3,
    .p-col-4,
    .p-col-5,
    .p-col-6,
    .p-col-7,
    .p-col-8,
    .p-col-9,
    .p-col-10,
    .p-col-11,
    .p-col-12,
    .p-col {
        padding: 0.5rem !important;
    }
}

//////////////// primeng grid css end ////////////


// /////////////////  Global start  //////////////////////
.p-component {
    font-variant-numeric: tabular-nums !important;
    font-family: var(--font-family-datasavi) !important;
    font-size: 0.8rem !important;
}

.p-datepicker-calendar,
.p-datepicker-buttonbar,
.p-datepicker-header {
    font-size: 0.7rem !important;
}

// .p-datepicker-buttonbar{
//     font-size: 0.7rem !important;
// }
// .p-datepicker-header{
//     font-size: 0.7rem !important;
// }

// /////////////////  Global end  //////////////////////

///// /////////////////  Ionic Header start  //////////////////////

ion-title {
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: unset !important;
    padding: 10px !important;
}

.header-md::after {
    bottom: -4px !important;
    //display: none !important;
}

ion-toolbar {
    --min-height: 38px !important;
    height: 38px !important;
    padding: 0 10px !important;
    font-family: var(--font-family-datasavi);
}

// .back-button-has-icon-only {
//     --border-radius: 50%;
//     min-width: 35px !important;
//     height: 35px !important;
//     --icon-font-size: 20px !important;
//     //--padding-start: 8px !important;
// }

.sc-ion-buttons-md-s .button {
    --padding-start: 2px !important;
    --padding-end: 2px !important;
}


// /////////////////  Global end  //////////////////////



// /////////////////  FormTable Dialog start  //////////////////////

.p-dialog .p-dialog-header {
    padding: 0.571rem 1rem !important;
    border-radius: 20px 20px 0 0 !important;
}

// /////////////////  FormTable Dialog end  //////////////////////

// /////////////////  P-Panel Start  //////////////////////


.chart-panel {
    .p-panel {
        background-color: #fff !important;
        // height: 100% !important;
        margin: 0px !important;
        box-shadow: var(--shadow);
        border-radius: 2px !important;

        .p-panel-header {
            // padding: 0rem 1rem !important;
            border: 0 !important;
            // background-color: #fff !important;
            // color: #333 !important;
            // font-weight: 500 !important;
            // padding: 0.857rem 1rem;
            /* font-weight: 600; */
            // font-weight: 700;
            background-color: var(--card-header-background-color) !important;
            color: #595959 !important;
            /*font-weight: 600 !important;*/
            font-size: 16px !important;
            // line-height: 32px;
            line-height: 32px !important;
            border-left: 4px solid #383838 !important;

            .p-panel-title,
            .ui-panel-title {
                // font-weight: 500 !important;
                // line-height: 2rem !important;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500 !important;
                font-size: 14px;
                line-height: 32px !important;
            }

            .p-button-label {
                display: none;
            }

            .p-multiselect {
                border: 1px solid #c8c8c8a8 !important;

                .p-multiselect-label {
                    padding: 0.14rem 0.429rem;
                }
            }
        }

        .p-panel-content {
            // padding: 0rem 1rem 1rem 1rem !important;
            border: 0 !important;

            .p-button[type=submit] {
                background-color: var(--primary-background-color) !important;
                margin-top: 10px;
                border: solid 1px var(--primary-background-color);
                border-radius: 2px;
                font: var(--font-family-datasavi) 0.7rem !important;
            }
        }

        .p-panel-footer {
            border: 0 !important;

            .p-button {
                text-align: right;
            }
        }

        .p-card {
            .p-card-content {
                padding: 0;
                font-size: 0.7rem !important;
                font-family: var(--font-family-datasavi);
            }
        }
    }
}

.p-panel {
    background-color: #fff !important;
    // height: 100% !important;
    margin: 0px !important;
    box-shadow: var(--shadow);
    border-radius: 2px !important;

    .p-panel-header {
        // padding: 0rem 1rem !important;
        border: 0 !important;
        // background-color: #fff !important;
        // color: #333 !important;
        // font-weight: 500 !important;
        // padding: 0.857rem 1rem;
        /* font-weight: 600; */
        // font-weight: 700;
        background-color: var(--card-header-background-color) !important;
        color: #595959 !important;
        /*font-weight: 600 !important;*/
        font-size: 16px !important;
        // line-height: 32px;
        line-height: 32px !important;

        // border-left: 4px solid #383838 !important;
        .p-panel-title,
        .ui-panel-title {
            // font-weight: 500 !important;
            // line-height: 2rem !important;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500 !important;
            font-size: 14px;
            line-height: 32px !important;
        }

        .p-button-label {
            display: none;
        }

        .p-multiselect {
            border: 1px solid #c8c8c8a8 !important;

            .p-multiselect-label {
                padding: 0.14rem 0.429rem;
            }
        }
    }

    .p-panel-content {
        // padding: 0rem 1rem 1rem 1rem !important;
        border: 0 !important;

        .p-button[type=submit] {
            background-color: var(--primary-background-color) !important;
            margin-top: 10px;
            border: solid 1px var(--primary-background-color);
            border-radius: 2px;
            font: var(--font-family-datasavi) 0.7rem !important;
        }

        // Sudarshan's Reset Button CSS
        .p-button[type=reset] {
            background: transparent !important;
            color: var(--secondary-font-color) !important;
            border: 1px solid var(--secondary-font-color) !important;
            margin-right: 1rem;
        }
    }

    .p-panel-footer {
        border: 0 !important;

        .p-button {
            text-align: right;
        }
    }

    .p-card {
        .p-card-content {
            padding: 0;
            font-size: 0.7rem !important;
            font-family: var(--font-family-datasavi);
        }
    }
}

.panel_includes_table {
    .p-panel {
        background-color: var(--panel-background-color) !important;

        .p-panel-header {
            font-family: var(--font-family-datasavi);
            font-size: 16px !important;
            font-weight: 500 !important;
            line-height: 24px !important;
            margin-left: 16px !important;
            padding: 0rem !important;
            border: 0 !important;
            background-color: var(--panel-background-color) !important;
            padding-top: 0.3rem !important;

            .p-panel-header-icon {
                font-size: 0.7rem !important;
                color: #5289FF;
                width: 2rem
            }
        }

        .p-panel-content {
            // padding: 0.8rem !important;
            background-color: var(--panel-background-color) !important;

            .p-datatable {
                font-family: var(--font-family-datasavi);
                font-size: 0.7rem !important;

                .p-datatable-thead,
                .p-datatable-tbody {
                    tr {
                        line-height: 300% !important;
                        // font-weight: 100;
                    }
                }

                .p-datatable-thead>tr>th {
                    background-color: #FEFCF9 !important;
                    border: 0px !important;
                    // font-weight: 500;
                    // line-height: 300%;
                    // font-size: 0.75rem!important;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px !important;
                    line-height: 150%;
                    /*border-right: 1px solid rgb(235, 232, 232) !important;*/
                    border-bottom: 1px solid rgb(235, 232, 232) !important;
                    color: var(--ion-color-medium-black);
                    height: 2.5rem;
                    // text-align: left !important;

                    div {
                        text-align: center;
                        // text-align: start;
                    }
                }

                // .p-datatable-tbody > tr {
                //  background-color: #fff;
                // }
            }
        }

        .p-fileupload {
            display: flex !important;

            .p-fileupload-row>div {
                width: auto !important;
            }
        }
    }
}

.search-box {
    border: 1px solid #c8c8c8a8 !important;
    font-size: 12px !important;
    width: 160px !important;
    padding: 5px !important;
    background-color: transparent !important;
    height: 24px !important;
    margin-right: 5px !important;
    border-radius: 3px;
}


// /////////////////  P-Panel End  //////////////////////


////////////////////List-View ////////////////////////////
.list-view {
    .p-panel {
        .p-panel-header {
            font: var(--font-family-datasavi) 0.75rem;
            font-weight: 600;
            line-height: 200%;
            background-color: var(--card-header-background-color);
            border: 1px solid var(--panel-border-color);
            border-radius: 4px 4px 0px 0px;
        }

        .list-view-item {
            font-family: var(--font-family-datasavi);
            font-size: 0.7rem !important;
            border-bottom: solid 1px var(--panel-border-color);
            line-height: 200%;
            margin-top: 10px;
        }

        .p-button.p-button-icon-only.p-button-rounded {
            font-size: 0.7rem !important;
            height: 1.5rem !important;
        }
    }
}


.list-view-item {
    .content {
        margin-bottom: 0.5rem !important;
        /*background-color: var(--ion-color-light-tint);*/
        border-radius: 3px;
        border: 1px solid var(--ion-color-primary-white);
        padding: 0.5rem;

        .content-data {
            color: #333;
        }

        .content-data>div.key-parameter {
            font-size: 11.7px !important;
            color: var(--sidemenu-color);
            // color:var( --ion-background-color)
        }

        .content-data>div.listview-value {
            margin-bottom: 4px;
            font-size: 12px;
        }
    }
}

.list-view-item {
    .content {
        .content-data {
            div {
                div.listview-value {
                    font-size: 12px;
                }

                div.key-parameter {
                    font-size: 10px !important;
                    color: var(--sidemenu-color);
                }
            }
        }
    }
}

.list-view-item:not(:first-child) {
    padding-left: 5px !important;
}

.listview {
    .p-dialog {
        width: 20% !important;
    }

    .p-dialog-content {
        padding: 1rem !important;
        border-top-left-radius: 3px !important;
        border-top-right-radius: 3px !important;
    }
}

////////////////////////////////////////////////////////



// /////////////////  P-card start  //////////////////////

.p-card {
    margin-bottom: 0px !important;
    height: 100% !important;
    font-family: var(--font-family-datasavi);

    .p-card-body {
        padding: 0 !important;
        ;
    }

    .p-card-title {
        font-size: 0.75rem !important;
        font-weight: 500 !important;
        font-family: var(--font-family-datasavi);
        line-height: 300%;
        background: var(--card-title-background-color);
        padding-left: 5px;
    }

    .p-card-content {
        padding-left: 1rem !important;
        padding-top: 0.5rem !important;
        padding-right: 1rem !important;
    }
}

.p-card {
    box-shadow: var(--shadow);
    border-radius: 2px !important;
}

// /////////////////  P-card End  //////////////////////

// /////////////////  Menubar Start  //////////////////////


.p-menubar {
    padding: 0.2rem !important;
    border: 0 !important;
    box-shadow: var(--shadow);
    //border-bottom: 1px solid #c8c8c8;
    border-radius: 0 !important;
    height: 40px !important;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
    height: 40px !important;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
    box-shadow: none !important;
}

// .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
//     font-weight: 400 !important;
//     color:#333333!important;
// }


.p-menubar .p-menuitem-link-active {
    // background-color: var(--active-sidemenu-color) !important;
    background-color: orange;
    // border: 1px solid #c8c8c8 !important;
    // margin-bottom: -4px !important;
    // margin-left: 4px !important;
    // border-bottom-color: var(--ion-background-color) !important;
    // border-top-left-radius: 5px !important;
    // border-top-right-radius: 5px !important;
    // border-bottom-left-radius: 0px !important;
    // border-bottom-right-radius: 0px !important;
    border-top: 3px solid var(--active-sidemenu-color) !important;
    //border-right: 1px solid var(--active-sidemenu-color) !important;
    border-bottom: 1px solid var(--active-sidemenu-color) !important;
    border-left: 1px solid var(--active-sidemenu-color) !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
    margin-right: 0 !important;
}

// /////////////////  p-menubar End  //////////////////////


.p-menu .p-menuitem-link {
    font-size: 0.8rem;
    border-bottom: solid 1px #f7f7f7;
}

.p-menu .p-menuitem-link:hover {
    background-color: var(--active-sidemenu-background-color) !important;
}

.p-menu .p-menuitem-link .p-menuitem-text:hover {
    color: #F27924 !important;
}

// ///////////////// Mainpage css Start  //////////////////////

#content {
    margin-top: 65px !important;
}

app-mainpage {
    margin-top: 38px !important;
}

ion-content {
    --padding-start: 15px !important;
    --padding-end: 15px !important;
    --padding-top: 15px !important;
    --padding-bottom: 15px !important;
    top: 5px;
}


// ///////////////// Mainpage css end  //////////////////////


// ///////////////// Accrodion start  //////////////////////

.p-accordion-header-link {
    background: #fff !important;
    border-color: transparent !important;
    color: #333 !important;
}

.p-accordion .p-accordion-content {
    border: 0 !important;
}

// ///////////////// Accordion css end  //////////////////////

// ///////////////// Notification css start  //////////////////////


app-notifications ion-content {
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --padding-top: 0px !important;
    --padding-bottom: 0px !important;
}

.notification-sidebar {
    .p-sidebar {
        max-width: 284px !important;
    }
}

// ///////////////// Notification css end  //////////////////////


// ///////////////// P-tooltip css start  //////////////////////

.p-tooltip-text {
    background: white !important;
    color: black !important;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #ffffff !important;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #f9f9f9 !important;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #fbfbfb !important;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #ffffff !important;
}

// ///////////////// P-tooltip css end  //////////////////////


.main-footer {
    background-color: #fff;
    //border-top: 1px solid #dee2e6;
    color: #869099;
    padding: 1rem;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    z-index: 100;
    flex-direction: row;
    justify-content: space-between;

    #copyright_text {
        display: flex;
        align-items: center;
    }
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    font-weight: 500 !important;
    white-space: nowrap !important;
    font-size: 0.8rem;
    font-family: var(--font-family-datasavi);
}

// /////////////////  formwithgrouping css start  //////////////////////

.formwithgrouping-tab {
    .p-panel {
        box-shadow: none !important;
        margin: 0 !important;
    }
}

// /////////////////  formwithgrouping css end  //////////////////////

// /////////////////  mobile tiles css start  //////////////////////
.create-mobile-tiles {

    .p-card {
        width: auto;
        background-color: #ADDFF6;
        border-radius: 10px !important;
    }
}

.create-mobile-tiles {
    div {
        strong {
            font-family: 'Segoe UI';
            font-style: normal;
            font-weight: 400;
            font-size: 16px !important;
            line-height: 15px;
            display: flex;
            // gap: 20px;
            justify-content: space-between;
        }

        // strong:hover {
        //     color: #F27924;
        // }
    }
}


.create-mobile-tiles {
    .p-card-body {
        padding: 0.5rem 0.5rem !important;

        // padding: 1.2rem 1.2rem 0.5rem 1.2rem !important;
        .p-card-content {
            padding: 0 !important;
        }
    }
}

.mobile-tiles {
    .p-card-body {
        padding: 1.2rem 1.2rem 1.4rem 1.2rem !important;

        /*line-height: 150%;*/
        .p-card-content {
            padding: 0 !important;
        }
    }

    .p-col {
        padding: 0 !important;

        .var-value {
            font-size: 14px !important;
            color: #5e676f;
        }

        .var-key {
            font-weight: 100;
            font-size: 11px;
        }
    }

    .module-key {
        font-size: medium;
        opacity: 0.6;
        padding: 0px !important;
    }
}

// /////////////////  mobile tiles css end  //////////////////////


.p-chip {
    background-color: #fff !important;
}



// /////////////////  Status Icon css  //////////////////////


.StatusIcon {
    text-align: center !important;

    .pi {
        text-align: center;
        font-size: 12px;
        padding: 5px 2px;
        width: 19px;
        font-size: 12px;
        height: 19px;
        //border: 1px solid gray;
        border-radius: 50%;
    }

    .green-icon {
        //background: lightgreen;
        color: green;
    }

    .red-icon {
        //background: darkred;
        color: red;
    }

    .yellow-icon {
        //background: yellow;
        color: #ffc800;
    }
}

.updownicon {
    text-align: center !important;

    .pi {
        text-align: center;
        font-size: 12px;
        padding: 3px;
        width: 16px;
        font-size: 10px !important;
        height: 16px;
        //border: 1px solid gray;
        border-radius: 50%;
        background-color: green;
        color: white;
    }

    .pi-minus {
        background-color: lightgreen !important;
        color: white;
    }
}

// /////////////////  Status Icon css end  //////////////////////

// /////////////////  Chat css Start //////////////////////


// .chat-card {
//     .p-panel-header, .p-panel-footer {
//         position: fixed !important;
//         width: 39%;
//         border: 0 !important;
//     }

//     .p-panel-footer {
//         bottom: 0;
//     }
// }

// /////////////////  Chat css end //////////////////////


////////////////////////////graph / chart css start////////////////////////////


.chart-panel {
    .p-panel {
        .p-panel-content {
            // padding: 0 5px 5px 5px 5px !important;
            padding: 0.571rem 0 !important;

            * {
                font-size: 12px !important;
                font-weight: 400;
            }
        }
    }
}

////////////////////////////graph / chart css end////////////////////////////

////////////////////////////start timeline////////////////////////////
.tilesgroupdata {

    .p-sm-1,
    .p-sm-2,
    .p-sm-3,
    .p-sm-4,
    .p-sm-5,
    .p-sm-6,
    .p-sm-7,
    .p-sm-8,
    .p-sm-9,
    .p-sm-10,
    .p-sm-11,
    .p-sm-12,
    .p-md-1,
    .p-md-2,
    .p-md-3,
    .p-md-4,
    .p-md-5,
    .p-md-6,
    .p-md-7,
    .p-md-8,
    .p-md-9,
    .p-md-10,
    .p-md-11,
    .p-md-12,
    .p-lg-1,
    .p-lg-2,
    .p-lg-3,
    .p-lg-4,
    .p-lg-5,
    .p-lg-6,
    .p-lg-7,
    .p-lg-8,
    .p-lg-9,
    .p-lg-10,
    .p-lg-11,
    .p-lg-12,
    .p-xl-1,
    .p-xl-2,
    .p-xl-3,
    .p-xl-4,
    .p-xl-5,
    .p-xl-6,
    .p-xl-7,
    .p-xl-8,
    .p-xl-9,
    .p-xl-10,
    .p-xl-11,
    .p-xl-12 {
        padding: 0 !important;
    }
}

.menuflow {
    .p-timeline {
        padding: 0px !important;
        overflow: auto !important;

        timeline-tile-header {
            text-align: left !important;
            font-size: 1.4em;
            color: #848484;
        }

        .custom-marker {
            display: flex;
            min-width: 2rem;
            width: 2rem;
            height: 2rem;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            border-radius: 50%;
            z-index: 1;
        }

        .p-timeline-event-content,
        .p-timeline-event-opposite {
            line-height: 1 !important;
        }

        p-timeline-horizontal .p-timeline-event-opposite,
        .p-timeline.p-timeline-horizontal .p-timeline-event-content {
            padding-top: 0.2rem !important;
        }

        .p-timeline-event:nth-child(even) {
            .p-timeline-event-content {
                text-align: left !important;
            }
        }

        .p-timeline-event {
            min-width: 220px;
            max-width: 250px;
            margin-right: 20px !important;
        }

        .p-timeline-event:first-child {
            margin-left: 0px !important;

            p-card::before {
                display: none !important;
            }
        }

        // .p-timeline-event:last-child {
        //     margin-right: 0px !important;
        //     p-card::after {
        //         display: none;
        //     }
        // }
        .p-timeline-event-content {
            padding: 0rem !important;
            min-height: 85px;
            //margin-right: 0.5rem;
            margin-bottom: 0.3rem;

            .p-card-title {
                color: #4F4F4F;
                margin-bottom: 0.2rem !important;
                margin-left: 0.5rem !important;
            }

            .p-card::after {
                content: "" !important;
                position: absolute !important;
                width: 0 !important;
                height: 0 !important;
                left: 0 !important;
                border-top: 43px solid transparent !important;
                border-left: 15px solid #fff !important;
                border-bottom: 42px solid transparent !important;
                margin: -85px 0 0 100% !important;
            }

            .p-card::before {
                content: "" !important;
                position: absolute !important;
                width: 0 !important;
                height: 0 !important;
                z-index: -1;
                background: #fff !important;
                border-top: 43px solid transparent !important;
                border-left: 15px solid var transparent !important;
                border-bottom: 42px solid transparent !important;
                margin: 0px 0 0 -15px !important;
            }

            .p-card {
                margin-bottom: 0 !important;
                border-radius: 5px !important;
                box-shadow: var(--shadow);
                height: 85px !important;
                width: 100% !important;
                padding: 0.4rem 0rem !important;

                //box-shadow: var(--shadow);
                .p-card-body {
                    padding: 0rem 0rem 0 0rem !important;

                    .p-card-content {
                        padding: 0 0.9rem !important;
                    }
                }
            }
        }



        .timelinelink a.disabled,
        .timelineicon a.disabled {
            pointer-events: none;
            cursor: not-allowed;
            color: #848484;
        }

        .timelinelink a,
        .timelineicon a {
            color: #1bb1e7;
        }

        .timelinelink a:hover,
        .timelineicon a:hover {
            color: rgb(255, 190, 0);
        }

        .timelineicon {
            padding: 0.5rem;
        }

        // .timelinemenu {
        //     margin-top: 0.5rem !important;
        // }
    }
}

@media screen and (max-width: 960px) {
    .customized-timeline {
        .p-timeline-event:nth-child(even) {
            flex-direction: row !important;

            .p-timeline-event-content {
                text-align: left !important;
            }
        }

        .p-timeline-event-opposite {
            flex: 0;
            display: none;
        }

        .p-card {
            margin-top: 1rem;
        }
    }
}

p-timeline {
    width: 100%;
    overflow: auto;
    margin-top: -10px !important;

    .p-grid {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0 !important;

        .p-col-12 {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
        }
    }
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 0 !important;
}

.p-timeline-horizontal .p-timeline-event-separator,
.p-timeline-event-opposite {
    display: none !important;
}

/////////////////////////////end timeline///////////////////////


.info,
.success,
.warning,
.error,
.validation {
    //border: 1px solid;
    margin: 10px 0px;
    padding: 15px 10px 15px 15px !important;
    background-repeat: no-repeat;
    background-position: 10px center;
}

.small-text {
    * {
        font-size: 0.6rem !important;
    }
}

.resend_otp_button,
.validate_otp_button {
    width: 45% !important;
}


///////////////////Breadcrumb Start//////////////////////
.p-breadcrumb {
    border: 0 !important;
    padding: 0.39rem 0.59rem !important;
    background: transparent !important;
    font-size: 0.8rem !important;
    max-height: fit-content;
}


.breadcrumb-div {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5px;
    padding: 0 0.5rem !important;
    background: var(--ion-background-color);
    box-shadow: 0 4px 10px #001d360a;

    //margin-bottom: 10px;
    .p-button:focus {
        box-shadow: none !important;
        background: #f5f5f5 !important;
        border: 0 !important;
    }

    .p-button.p-button-secondary {
        font-size: 0.8rem;
        padding: 0.5rem !important;
        color: #5E5E5E;
    }

    // .p-button.p-button-icon-only.p-button-rounded {
    //     height: 2rem !important;
    // }
    // .p-button{
    //     background-color: lightgray !important;
    //     color: #007ad9 !important;
    //     border: 0 !important;
    // }
    // .p-button.p-button-text:enabled:hover {
    //     background: lightgray !important;
    // }
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0.18rem 0.5rem 0 0.5rem !important;
    font-size: 0.5rem !important;
}

///////////////////Breadcrumb end//////////////////////


///////////////////Notification Panel start//////////////////////
.Notifications {

    .p-datatable td {
        // padding: 17px !important;
        // height: 32px !important;
        line-height: 150%;
    }

    .notification-icon {
        padding: 0.05rem !important;
        color: #1bb1e7;
    }

    .notification-message {
        color: #424a48;
        margin-left: 0.4rem !important;
        font-weight: 500;
    }


    .notification-message:hover {
        color: rgb(255, 190, 0);
    }
}

///////////////////Notification Panel end//////////////////////
.p-checkbox .p-checkbox-box {
    width: 15px !important;
    height: 15px !important;
    display: flex !important;
    align-self: center !important;
}

// Sudarshan's Code
.p-inputswitch .p-inputswitch-box {
    width: 15px !important;
    height: 15px !important;
    display: flex !important;
    align-self: center !important;
}


//////////////////////////////module flow/////////////////////////////
// .moduleflow {
//     width: 100px !important;
//     height: 200px !important;
//     .p-submenu-list{
//         display: flex !important;
//         flex-direction: column;
//         // align-items: center;
//         // justify-content: center;
//         // width: 50%;
//         width: 300px !important;
//     }
//     .p-menubar {
//         background-color: var(--ion-background-color);
//         box-shadow: none !important;
//         padding: 0 1rem !important;
//         margin-top: 1px;

//         .p-menuitem {
//             width: calc(50% - 20px);

//             .p-menuitem-link {
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//             }
//         }

//         .p-menuitem:not(:first-child) {
//             margin-left: 20px;
//         }

//     }

//     p-menubarsub {
//         width: 100% !important;

//         ul {
//             list-style: none;
//             margin: 0;
//             padding: 0;
//             display: flex;
//             align-items: center;

//             li {
//                 background-color: #fff;

//                 a {
//                     padding: auto 0.875rem !important;
//                 }
//             }

//             li:not(:last-child):after {
//                 content: "" !important;
//                 position: absolute !important;
//                 width: 0 !important;
//                 height: 0 !important;
//                 left: 0 !important;
//                 border-top: 20px solid transparent !important;
//                 border-left: 20px solid #fff !important;
//                 border-bottom: 20px solid transparent !important;
//                 margin: -40px 0 0 100% !important;
//             }

//             li:not(:first-child):before {
//                 content: "" !important;
//                 position: absolute !important;
//                 width: 0 !important;
//                 height: 0 !important;
//                 left: -15px !important;
//                 border-top: 20px solid #fff !important;
//                 border-left: 20px solid transparent !important;
//                 border-bottom: 20px solid #fff !important;
//                 margin: 0 100% 0 0 !important;
//                 //  transform: scaleX(-1);

//             }

//             .p-menuitem-link-active+li:not(:last-child):after {
//                 border-top: 20px solid red !important;
//                 //border-left: 20px solid transparent !important;
//                 border-bottom: 20px solid red !important;
//                 // background-color: green !important;
//             }
//         }
//         .li:not(:last-child):after+.p-menuitem-link-active {
//             border-top: 20px solid red !important;
//             //border-left: 20px solid transparent !important;
//             border-bottom: 20px solid red !important;
//             background-color: green !important;
//         }
//         // li:after > a:not(.p-menuitem-link-active){
//         //     content: "" !important;
//         //     position: absolute !important;
//         //     width: 0 !important;
//         //     height: 0 !important;
//         //     left: 0 !important;
//         //     border-top: 20px solid transparent !important;
//         //     border-left: 20px solid #fff !important;
//         //     border-bottom: 20px solid transparent !important;
//         //     margin: -40px 0 0 100% !important;
//         // }

//         .p-submenu-list {
//             display: block;
//             width: 300px;

//             li {
//                 margin-left: 15px !important;
//             }
//         }
//     }

// }


////////////////////////////////////end of moduleflow//////////////////////////////
///
/// //////////////////////////////////////////moduleflow 1 //////////////////////////////////////@at-root

.moduleflow {
    width: 100% !important;
    height: auto !important;
    background-color: var(--ion-background-color);
    box-shadow: none !important;
    padding: 0.1rem 15px 0.1rem 15px !important;
    margin-top: 1px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 14px;
        font-family: var(--font-family-datasavi);

        li.active {
            background-color: var(--active-sidemenu-background-color);

            a {
                color: var(--active-color) !important;
            }
        }

        li.active:not(:last-child):after {
            border-left: 20px solid var(--active-sidemenu-background-color) !important;
            // opacity: 9 !important;
        }

        li.active:not(:first-child):before {
            border-top: 20px solid var(--active-sidemenu-background-color) !important;
            border-bottom: 20px solid var(--active-sidemenu-background-color) !important;
            // opacity: 9 !important;
        }

        li {
            background-color: #fff;
            width: calc(50% - 20px);
            height: 40px;
            // margin-left: 25px;
            display: flex;
            align-items: center;
            position: relative;

            a {
                padding: 10px !important;
                align-self: center;
                text-decoration: none !important;
                color: #333 !important;
            }
        }

        li:not(:first-child) {
            margin-left: 25px;
        }

        li:not(:last-child):after {
            content: "" !important;
            position: absolute !important;
            width: 0 !important;
            height: 0 !important;
            left: 0px !important;
            border-top: 20px solid transparent !important;
            border-left: 20px solid #fff !important;
            border-bottom: 20px solid transparent !important;
            margin: -2px 0 0 100% !important;
        }

        li:not(:first-child):before {
            content: "" !important;
            position: absolute !important;
            width: 0 !important;
            height: 0 !important;
            left: -20px !important;
            border-top: 20px solid #fff !important;
            border-left: 20px solid transparent !important;
            border-bottom: 20px solid #fff !important;
            margin: 0 100% 0 0 !important;
            //  transform: scaleX(-1);
        }
    }
}

///////////////////////////////////enf of menuflow 1 ////////////////////////////////////////////

.RuleLink>button {
    text-align: left;
    width: 100%;
}

////////////////////////////////////////////////////////////////////////////////////////////

.card_display {
    border-radius: 5rem;

    .p-card-header {
        background-color: var(--card-header-background-color);
        line-height: 200%;
        padding: 1em;
        font-size: 1rem !important;
    }

    .p-card .p-card-body {
        padding: 0 !important;
    }

    .p-card .p-card-content {
        padding: 1rem !important;
        font-size: 0.8rem;
        font-family: var(--font-family-datasavi);

        .card-field {
            margin-top: 0.2rem;
            font-size: 0.75rem;
            font-weight: 200 !important;
        }
    }

    .p-card-footer {
        background-color: var(--card-header-background-color);
        line-height: 200%;
        font-size: 0.8rem !important;
        padding: 1rem !important;
    }
}

.p-chip {
    background-color: var(--card-header-background-color) !important;
    color: #333333;
    border-radius: 16px !important;
    padding: 0 0.429rem;
    margin: 0.2rem;

    .p-chip-text {
        font-size: 0.8rem
    }
}


.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--ion-toolbar-background) !important;
    background: var(--ion-toolbar-background) !important;
}

// Sudarshan's Code
.p-inputswitch .p-inputswitch-box.p-highlight {
    border-color: var(--ion-toolbar-background) !important;
    background: var(--ion-toolbar-background) !important;
}


.module-header {
    font-size: 1.1rem;
    font-family: var(--font-family-datasavi);
    display: flex;

    .p-button.p-button-text {
        font-size: 0.9rem;
        font-family: var(--font-family-datasavi);
        color: var(--ion-toolbar-background) !important;
        cursor: pointer;
    }
}


/////////////////////process-flow////////////////////
.processflow-dialog .p-dialog {
    width: 100vw !important;

    .p-dialog-footer {
        border: 0 !important;

        .p-button {
            margin-left: 8px;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            width: 120px;
            height: 40px;
            border: 1px solid var(--anchor-text-color);
            border-radius: 2px;
        }

        .p-button-secondary {
            background: var(--secondary-background-color);
            color: var(--secondary-font-color);
            border: solid 1px var(--secondary-font-color);
        }

        .p-button-secondary:hover {
            background: var(--hover-background-color);
            color: var(--hover-font-color);
        }

        .p-button-primary {
            background: var(--primary-background-color) !important;
            color: var(--primary-font-color) !important;
            border: solid 1px var(--primary-font-color) !important;
        }

        .p-button-primary:hover {
            background: var(--hover-background-color);
            color: var(--hover-font-color);
            border: solid 1px var(--hover-font-color);
        }
    }
}

.process-flow {
    max-width: 80vw !important;

    .p-panel-content {
        /*padding: 0 !important;*/
        padding-top: var(--ion-grid-padding-xl, var(--ion-grid-padding, 20px)) !important;
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .p-panel-header {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 200%;
    }

    .process-tile {
        font-size: 0.7rem !important;
        font-family: var(--font-family-datasavi);
        width: 100%;
        background-color: #fafafa;
        padding: 0 10px 0 10px;

        .status-icon {
            line-height: 250%;
            font-size: 0.5rem !important;

            .p-button.p-button-icon-only.p-button-rounded {
                background-color: #52C41A !important;
                color: #ffffff;
                width: 0.95rem !important;
                height: 0.95rem !important;
                padding: 0.1rem !important;
                font-weight: 200;

                .pi {
                    font-size: 0.6rem !important;
                }
            }
        }

        .action-icon {
            padding: 0 !important;
            font-size: 0.65rem !important;
            margin-left: 5px;

            .p-button.p-button-icon-only.p-button-rounded {
                background-color: transparent !important;
            }
        }

        .process-name {
            line-height: 250%;
        }
    }
}

///////////////End of process flow//////////////////////



////////////////////////////start processflow////////////////////////////




.processflow {

    timeline-tile-header {
        text-align: left !important;
        font-size: 1.4em;
        color: #848484;
    }



    .p-timeline-horizontal .p-timeline-event {
        max-width: 100%;
        min-width: 100%;
    }



    .p-timeline .p-timeline-event-marker {
        background-color: transparent !important;
        width: 0px !important;
        height: 0px !important;
    }







    .timelinemenu {
        display: flex;
        flex-wrap: wrap !important;
        flex-direction: row;
        align-items: center;
        /*justify-content: space-around;*/

        /*.processDetail {
            min-width: 25% !important;
            display: flex;
            justify-content: center;
        }*/

        .pi-check-circle {
            color: green !important;
        }

        .pi-times-circle {
            color: red !important;
        }

        .pi-question-circle {
            color: rgb(143, 216, 250);
        }

        .timelinelink>a {
            // font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 13px !important;
            // line-height: 24px;

            color: #000000;
        }

        .timelinelink>p {
            // font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 11px !important;
            // line-height: 19px;
            color: #A1A1A1;
        }





        .green {
            color: green !important;
        }







        .red {
            color: red !important;
        }







        .orange {
            color: orange !important;
        }
    }







    .p-timeline-event-connector {
        height: 0 !important;

        .p-card::after {
            border-top: 0px !important;
            border-left: 0px !important;
        }
    }







    .custom-marker {
        display: flex;
        flex-wrap: wrap;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 50%;
        z-index: 1;
    }







    ::ng-deep {



        .p-timeline-event-content,
        .p-timeline-event-opposite {
            line-height: 1;
        }
    }
}





///////////////////////////////////////////////////


///////////////////dashboard (after login)////////////////////////////


.appGroup {
    max-width: 100%;
    margin: auto;

    .logo_name {
        font-size: 18px !important;
        line-height: 32px;
        display: flex;
        flex-direction: row;
        color: #5E5E5E;
    }

    .logo_name:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #D9D9D9;
        ;
        margin-left: 10px;
        align-self: center;
    }
}

.sidebar.open li {
    font-family: 'Inter' !important;
    font-style: normal !important;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    width: 266px;
    height: 128px;

    .timeStamp {
        font-size: 8px;
        line-height: 16px;
        color: #B0B0B0;
    }

    button {
        padding: 2px 16px;
        font-size: 10px;
        line-height: 18px;
        color: #52C41A;
        background: #F6FFED;
        border: 1px solid #B7EB8F;
        border-radius: 4px;
    }

    .links_name {
        font-size: 14px;
        line-height: 20px;
    }

    .active {
        border: 1px solid #F27924;
        box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
        border-radius: 8px;

        .links_name {
            color: #F27924;
        }

        .material-icons {
            color: #F27924;
            font-size: 3.5rem;
            font-weight: 500;
            line-height: 20px;
            font-weight: lighter !important;
            border-radius: 4px;
        }
    }
}


///dialog ///
///
///

p-dialog {
    ion-grid {
        padding: 1 rem !important;
    }
}

.p-dialog-content {
    padding: 0 !important;
    border-radius: 20px 20px 0 0 !important;
}

.p-dialog-custome-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-radius: 20px 20px 0 0 !important;
    flex-shrink: 0;
    background-color: #f4f4f4;
    padding: 0.571rem 1rem !important;

    .p-dialog-header-close {
        position: absolute !important;
        top: -40px !important;
        right: 10px;
        color: var(--ion-toolbar-color) !important;
        background-color: transparent !important;
    }
}

.p-dialog-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 2.2rem;
}

.p-dialog-header-icons {
    .p-dialog-header-icon-clone {
        color: var(--ion-color-light-blue);
    }

    .p-dialog-header-icon-trash {
        color: #FF4141;
    }

    .p-dialog-header-icon-close {
        position: absolute !important;
        top: 27px !important;
        right: 10px;
        color: #FF4141 !important;
        background-color: transparent !important;
    }
}

.p-dialog {
    width: 100% !important;
    margin: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    border: none !important;

    // .p-dialog-header{
    //     // border-top-right-radius: 20px !important;
    //     // border-top-left-radius: 20px !important;
    //     // border-bottom: 0px !important;
    //     .p-dialog-header-close{
    //         position: absolute !important;
    //         top: -40px !important;
    //         color: var(--ion-toolbar-color) !important;
    //     }
    // }
    .p-dialog-footer {
        border-top: 0px !important;
        background-color: var(--footer-background-color) !important;

        .p-button {
            background: var(--footer-background-color) !important;
            border: 1px solid var(--hover-font-color) !important;
            padding: 8px 24px !important;
            color: var(--secondary-font-color);
        }

        .p-button:hover {
            background-color: #fff;
            color: var(--secondary-font-color)
        }

        .p-button-primary {
            background-color: var(--primary-background-color) !important;
            color: var(--primary-font-color) !important;
        }
    }
}

.p-dialog .p-dialog-header {
    display: none !important;
}

.p-inputswitch {
    position: relative;
}

//Sudarshan's Changes Starts Here

.p-inputswitch-slider {
    width: 35px !important;
    height: 16px !important;
    margin-left: 10px;
    border-radius: 20px !important;
    border: 1px solid #AAAAAA;
    position: absolute;
    top: 10px !important;
}

.p-inputswitch .p-inputswitch-slider:before {
    width: 12.57px !important;
    height: 12.57px !important;
    left: 0px !important;
    margin-top: -0.4rem !important;
    background-color: #FFFFFF !important;
}

.p-inputswitch.p-inputswitch-checked:hover .p-inputswitch-slider {
    background: #F27924 !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #F27924 !important;
}

//Sudarshan's Changes Ends Here



// submit button on the right for the form display type this class needs to be added to the wrapper of the button
.formSaveRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

//tree

.p-splitter-panel {
    overflow: scroll;

    .tree_panel {
        width: 100% !important;
    }
}

//CRUD Table row highlight on row select and crud table dialog box style changes

.p-datatable .p-datatable-tbody>tr {
    background: #fff;
    color: #333333 !important;
    transition: background-color 0.2s, box-shadow 0.2s;
    outline-color: #8dcdff;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
    background: var(--ion-color-light-grey) !important;
    color: #ffffff;
}

.p-dialog-custom-content {
    padding-left: unset;
    padding-right: unset;
    padding-inline-start: var(--ion-grid-padding-xl, var(--ion-grid-padding, 120px));
    padding-inline-end: var(--ion-grid-padding-xl, var(--ion-grid-padding, 120px));
    padding-top: var(--ion-grid-padding-xl, var(--ion-grid-padding, 20px));
}

//workflow

.mobile-tiles {
    .workflow-value {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--ion-color-dark-shade);
    }

    .workflow-key {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: var(--ion-color-medium-shade) // color:var(--ion-color-dark-grey)
    }

    .workflow-button {
        background: var(--active-sidemenu-color);
        border: 1px solid var(--active-sidemenu-color);
        border-radius: 4px;
    }

    .workflow-button:hover {
        background: var(--active-sidemenu-color) !important;
        border: 1px solid var(--active-sidemenu-color) !important;
        border-radius: 4px !important;
    }
}

.module {
    display: flex;
    gap: 10px;

    span>button {
        color: #999 !important;
        background: #ffba01;
        border: 1px solid #ffba01 !important;
    }
}

////////////////////////////start processflow////////////////////////////
.processflow {
    width: 100% !important;

    timeline-tile-header {
        text-align: left !important;
        font-size: 1.4em;
        color: #848484;
    }

    .p-timeline-horizontal .p-timeline-event {
        max-width: 100%;
        min-width: 100%;
        margin-right: 0px !important;
    }
}

.sidebar-inputControl {
    text-align: left;


    .timelinemenu {
        display: flex;
        flex-wrap: wrap !important;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;



        .green {
            color: green !important;
        }



        .red {
            color: red !important;
        }

        .p-dropdown {
            width: 90%;
        }
    }

    .listview {

        .p-timeline-event-connector {
            height: 0 !important;

            .p-card::after {
                border-top: 0px !important;
                border-left: 0px !important;
            }
        }



        .custom-marker {
            display: flex;
            flex-wrap: wrap;
            width: 2rem;
            height: 2rem;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            border-radius: 50%;
            z-index: 1;
        }



        ::ng-deep {

            .p-timeline-event-content,
            .p-timeline-event-opposite {
                line-height: 1;
            }
        }
    }

    .module {
        display: flex;
        gap: 10px;

        span>button {
            color: #999 !important;
            background: #ffba01;
            border: 1px solid #ffba01 !important;
        }

        span>button.changes {
            background-color: #fff4eb !important;
            color: #f27924 !important;
        }
    }

    ///////////////////////////////////////////////////
    .filter {
        .ui-button {
            //filter submit button
            width: 100% !important;
        }

        .filterInputs {
            margin-top: 10px;
        }

        .filter-label {
            font-size: 12px !important;
            margin-left: 5px
        }
    }

    .listview {

        .p-panel {
            .p-panel-header {
                display: flex;
                flex-direction: row;

                .p-button-label {
                    display: block !important;
                }

                .p-panel-header-icon {
                    width: 206px !important;
                    height: 40px;
                    border-radius: 10px;
                    background: #fff;
                    color: #383838;
                }

                .p-panel-header-icon:hover {
                    color: var(--active-color) !important;
                    background-color: var(--active-sidemenu-background-color) !important;
                }

                .p-panel-header-icon:not(:first-child) {
                    margin-left: 10px;
                }

                .p-panel-header-icon:enabled:hover {
                    background-color: var(--active-sidemenu-background-color);
                    color: var(--active-color) !important;
                }
            }
        }
    }

    ///////////////////////////////////////////////////
    .filter {
        span {
            display: flex;
            align-items: center;

            .p-column-filter {
                .p-column-filter-menu-button {
                    align-items: self-start !important;

                    span {
                        margin-top: 5px !important;
                        font-size: 11px !important;
                    }
                }
            }
        }
    }

    tr {
        th {
            .filter {
                div {
                    .p-column-filter {
                        .p-column-filter-menu-button {
                            align-items: self-start !important;

                            span {
                                margin-top: 5px !important;
                                font-size: 11px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    tr {
        th {
            .p-md-4 {
                padding: 0 !important;
            }

            .p-md-8 {
                padding: 0 !important;
            }

            .p-md-6 {
                padding: 0 !important;
            }

            .p-md-7 {
                padding: 0 !important;
            }

            .p-md-5 {
                padding: 0 !important;
            }

            .p-md-2 {
                padding: 0 !important;
            }
        }
    }

    .summernote {
        .p-splitter-panel {
            //    width: 100%!important;
            overflow: auto;
            flex-grow: unset !important;
        }

        .p-splitter-panel-nested {
            display: block;
        }

        .p-splitter-horizontal {
            width: 100%;
        }
    }

    .tree_panel {
        .p-tree .p-tree-filter-container .p-tree-filter {
            border-radius: 10px;
            padding: 10px !important;
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content {
            // color:#f27924;
            transition: none;
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content:hover {
            background-color: #ffecef !important;
            color: #f27924 !important;
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content :hover {
            color: #f27924;
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
            background: #ffecef;
            color: #f27924;
            border-bottom: 2px solid #F27924;
            transition: none !important;
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
            box-shadow: none !important;
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
            // color:#f27924;
            color: #333333;
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
            color: #f27924;
        }

        .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler {
            color: #f27924 !important;
        }
    }

    .summernote-buttons {
        width: 100%;

        i {
            color: #5289ff;
            margin-bottom: 1rem;
            font-size: 20px;
            margin-top: 10px;
            margin-right: 10px;
            cursor: pointer;
        }

        i:hover {
            color: #f27924;
        }

        i:not(:first-child) {
            margin-left: 10px;
        }
    }
}

.hiddenFilters {

    // line-height: 500px!important;
    th {
        div {
            .containsFilter {
                padding: 0 !important;

                .p-input-icon-right>i:last-of-type {
                    right: 0.429rem;
                    color: #848484;
                    font-size: 10px;
                    position: absolute;
                    top: 23px;
                    width: 0.5rem
                }

                .p-inputtext {
                    min-width: 100% !important;
                    font-size: 10px !important;
                }
            }

            .advanceFilter {
                padding: 0 !important;

                .p-column-filter {
                    .p-column-filter-menu-button {
                        width: 1rem !important;
                        height: 1rem !important;
                        border-radius: 50% !important;
                        // align-items: self-start !important;

                        span {
                            // margin-top: 5px !important;
                            font-size: 11px !important;
                            margin-left: 2px !important;
                        }
                    }
                }
            }
        }
    }
}

.panel_includes_table .p-panel .p-panel-content .p-datatable .p-datatable-thead>.hiddenFilters>th {
    line-height: 39px !important;
}

.hiddenFilters {
    line-height: 39px;

    th {
        div {
            .contains-filter {
                padding: 0 !important;

                .p-input-icon-right>i:last-of-type {
                    right: 0.429rem;
                    color: #848484;
                    font-size: 10px;
                    position: absolute;
                    top: 23px;
                    width: 0.5rem
                }

                .p-inputtext {
                    min-width: 100% !important;
                    font-size: 10px !important;
                }
            }

            .advance-filter {
                padding: 0 !important;

                .p-column-filter {
                    .p-column-filter-menu-button {
                        width: 1rem !important;
                        height: 1rem !important;
                        border-radius: 50% !important;
                        // align-items: self-start !important;

                        span {
                            // margin-top: 5px !important;
                            font-size: 11px !important;
                            margin-left: 2px !important;
                            // position: absolute;
                        }
                    }
                }
            }
        }
    }
}

// Sudarshan's Code Starts Here

#custDialog {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background: rgba(0, 0, 0, 0.35) !important;
    z-index: 10000 !important;
    width: 100% !important;
    height: 500% !important;
    cursor: wait !important;

    .custDialog {
        background: -webkit-linear-gradient(top, #ffffff, #fdf8ff);
        position: absolute !important;
        transform: translate(110%, 55%) !important;
        width: 30% !important;
        text-align: left;
        padding: 1% !important;
        box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
    }
}

.alignRight {
    text-align: end;

    .p-button-secondary {
        background: transparent !important;
        color: var(--secondary-font-color) !important;
        border: 1px solid var(--secondary-font-color) !important;
        margin-right: 1rem;
    }

    .p-button-primary {
        background: var(--primary-background-color);
        color: var(--primary-font-color);
        border: 1px solid var(--primary-font-color);
        margin-right: 1rem;
    }

    .p-button-primary:hover {
        background: var(--hover-background-color) !important;
        color: var(--hover-font-color) !important;
        border: 1px solid var(--hover-font-color) !important;
    }

    .p-button.p-button-secondary:enabled:hover {
        background: #546e7a !important;
        color: #fff !important;
        border-color: #546e7a !important;
    }
}

// Sudarshan's Code Ends Here

//Sudarshan - Radio Button

.custom-radio {
    margin-right: 5%;
}

.custom-radio .p-radiobutton-box.p-highlight {
    background-color: #F27924 !important;
    border-color: #F27924 !important;
}

.custom-radio .p-radiobutton-box.p-highlight:before {
    border-color: #F27924 !important;
    background-color: #F27924 !important;
}

.custom-radio .p-radiobutton-box {
    border-color: #F27924 !important;
}

.custom-radio.p-focus .p-radiobutton-box {
    box-shadow: 0 0 0 2px #F27924 !important;
}

//Sudarshan - radio Button

// Sudarshan - Divider
.cust-divider {
    color: #F27924 !important;
    
    .p-divider-solid.p-divider-horizontal:before {
        border-color: #F27924 !important;
    }
}

// Sudarshan - Divider 

.orgColor {
    color: #F27924 !important;
}

// Expression Maker CSS Starts here

.greyColor {
    color: grey;
}

.custom-orange-button.p-button{
    background-color: #F27924 !important;
    color: white !important;
    border-color: orangered !important;
  }

  .button-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .spacing {
    margin-right:10px !important; 
    margin-bottom:10px !important;
    font-size: 0.8rem !important;
  }
  
  .expression h6 {
    color:#F27924;
    display: inline-block;
    margin-right: 10px;
  }

  .scrollable-body{
    height:90% !important;
    overflow-y: scroll !important;
  }

  #custExpDialog {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background: rgba(0, 0, 0, 0.35) !important;
    z-index: 10000 !important;
    width: 100% !important;
    height: 500% !important;

    .custExpDialog {
        background: -webkit-linear-gradient(top, #ffffff, #fdf8ff);
        position: absolute !important;
        transform: translate(20%, 5%) !important;
        width: 75% !important;
        height: 18% !important;
        text-align: left;
        padding: 2% !important;
        box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
    }
}

.custSelect .p-selectbutton .p-button.p-highlight {
    background: #F27924 !important;
    border-color: #F27924 !important;
    color: #ffffff;
}

.highlighted-button {
    background-color: #F27924 !important;
    color: #ffffff !important; /* Change this to your desired background color */
  }

// Expression Maker CSS Ends here